import Vue from "vue";
import axios from 'axios';
var $ = require('jquery');

import store from './Store.js';
import calculator from './components/calculator'

new Vue({
	store,
	components: {
		calculator
	}
}).$mount("#app");
