<template>
	<div class="calculator">
		<h1 v-if="!projectName" class="heading heading--md heading--mblg">Add a Project</h1>
		<h1 v-else class="heading heading--md heading--mblg"> {{ projectName }} </h1>

		<transition
			appear
		    appear-active-class="animated fadeInRight"
			name="custom-classes-transition"
			:enter-active-class="'animated fadeInRight'"
			:leave-active-class="'animated fadeOutLeft'"
			mode="out-in"
		>
			<projectDetails
			v-on:project-name="setProjectName($event)"
			v-if="1 === activeField"
			></projectDetails>

			<room
			v-if="2 === activeField"
			@add-room-data="addRoomDetails($event)"
			></room>

			<projectOverview
			v-if="3 === activeField"
			:projectName="projectName"
			></projectOverview>
		</transition>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';

	import projectDetails from './steps/projectDetails';
	import projectOverview from './steps/projectOverview';
	import room from './steps/room';

	export default {
		components: {
			projectDetails,
			projectOverview,
			room,
		},

		data() {
			return {
				activeField: 1,
				projectName: '',
			}
		},

		methods: {
		    setProjectName( event ) {
		    	this.projectName = event;
		    },

			prev () {
	          	this.activeField -= 1
	        },

	        next () {
	          	this.activeField += 1
	        },
		}
	};

</script>

