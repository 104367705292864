import Vue from 'vue'
import Vuex from 'vuex'

Vue.use( Vuex )

/*
	Import all modules for the application
 */

import { calculator } from './modules/calculator.js'

export default new Vuex.Store({
	modules: {
		calculator
	}
})
