var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "calculator__inner calculator__inner--flex" },
    [
      _c(
        "div",
        { staticClass: "calculator__col" },
        [
          _c("accordion", { attrs: { name: "Room Details" } }, [
            _c("div", { staticClass: "calculator__group" }, [
              _c(
                "label",
                {
                  staticClass: "calculator__label",
                  attrs: { for: "project-name" }
                },
                [_vm._v("Enter Room Name")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.room.name,
                    expression: "room.name"
                  }
                ],
                staticClass: "calculator__input",
                attrs: {
                  type: "text",
                  name: "room-name",
                  placeholder: "Room Name"
                },
                domProps: { value: _vm.room.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.room, "name", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "calculator__group" }, [
              _c(
                "label",
                { staticClass: "calculator__label", attrs: { for: "wattage" } },
                [_vm._v("Select Wattage")]
              ),
              _vm._v(" "),
              _c(
                "select",
                {
                  staticClass: "calculator__input calculator__input--select",
                  attrs: { name: "wattage" },
                  on: {
                    change: function($event) {
                      return _vm.onChange($event, "wattage")
                    }
                  }
                },
                [
                  _c(
                    "option",
                    { attrs: { disabled: "", selected: "", value: "" } },
                    [_vm._v("Please select one")]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.wattages, function(wattage) {
                    return _c(
                      "option",
                      { domProps: { value: wattage.value } },
                      [_vm._v(_vm._s(wattage.name))]
                    )
                  })
                ],
                2
              )
            ]),
            _vm._v(" "),
            _vm.room.wattageName === "4.8" || _vm.room.wattageName === "9.6"
              ? _c(
                  "div",
                  { staticClass: "calculator__group calculator__group--flex" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "calculator__label",
                        attrs: { for: "kelvin" }
                      },
                      [_vm._v("Select Kelvin")]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "calculator__radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.room.kelvin,
                            expression: "room.kelvin"
                          }
                        ],
                        attrs: { id: "2700", type: "radio", value: "2700" },
                        domProps: { checked: _vm._q(_vm.room.kelvin, "2700") },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.room, "kelvin", "2700")
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "2700" } }, [_vm._v("2700")])
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "calculator__radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.room.kelvin,
                            expression: "room.kelvin"
                          }
                        ],
                        attrs: { id: "3000", type: "radio", value: "3000" },
                        domProps: { checked: _vm._q(_vm.room.kelvin, "3000") },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.room, "kelvin", "3000")
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "3000" } }, [_vm._v("3000")])
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "calculator__radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.room.kelvin,
                            expression: "room.kelvin"
                          }
                        ],
                        attrs: { id: "4000", type: "radio", value: "4000" },
                        domProps: { checked: _vm._q(_vm.room.kelvin, "4000") },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.room, "kelvin", "4000")
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "4000" } }, [_vm._v("4000")])
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "calculator__radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.room.kelvin,
                            expression: "room.kelvin"
                          }
                        ],
                        attrs: { id: "6000", type: "radio", value: "6000" },
                        domProps: { checked: _vm._q(_vm.room.kelvin, "6000") },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.room, "kelvin", "6000")
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "6000" } }, [_vm._v("6000")])
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.room.wattageName === "14.4 rgb" ||
            _vm.room.wattageName === "14.4 rgbw"
              ? _c(
                  "div",
                  { staticClass: "calculator__group calculator__group--flex" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "calculator__label",
                        attrs: { for: "kelvin" }
                      },
                      [_vm._v("Select Kelvin")]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "calculator__radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.room.kelvin,
                            expression: "room.kelvin"
                          }
                        ],
                        attrs: { id: "RGB", type: "radio", value: "RGB" },
                        domProps: { checked: _vm._q(_vm.room.kelvin, "RGB") },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.room, "kelvin", "RGB")
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "RGB" } }, [_vm._v("RGB")])
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "calculator__radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.room.kelvin,
                            expression: "room.kelvin"
                          }
                        ],
                        attrs: {
                          id: "RGBWW+2700K",
                          type: "radio",
                          value: "RGBWW+2700K"
                        },
                        domProps: {
                          checked: _vm._q(_vm.room.kelvin, "RGBWW+2700K")
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.room, "kelvin", "RGBWW+2700K")
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "RGBWW+2700K" } }, [
                        _vm._v("RGBWW+2700K")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "calculator__radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.room.kelvin,
                            expression: "room.kelvin"
                          }
                        ],
                        attrs: {
                          id: "RGBW+4000K",
                          type: "radio",
                          value: "RGBW+4000K"
                        },
                        domProps: {
                          checked: _vm._q(_vm.room.kelvin, "RGBW+4000K")
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.room, "kelvin", "RGBW+4000K")
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "RGBW+4000K" } }, [
                        _vm._v("RGBW+4000K")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "calculator__radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.room.kelvin,
                            expression: "room.kelvin"
                          }
                        ],
                        attrs: {
                          id: "RGBCW+6000K",
                          type: "radio",
                          value: "RGBCW+6000K"
                        },
                        domProps: {
                          checked: _vm._q(_vm.room.kelvin, "RGBCW+6000K")
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.room, "kelvin", "RGBCW+6000K")
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "RGBCW+6000K" } }, [
                        _vm._v("RGBCW+6000K")
                      ])
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "calculator__group" }, [
              _c(
                "label",
                {
                  staticClass: "calculator__label",
                  attrs: { for: "meterLength" }
                },
                [_vm._v("Enter Meter Length")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.room.meterLength1,
                    expression: "room.meterLength1"
                  }
                ],
                staticClass: "calculator__input",
                attrs: {
                  type: "number",
                  step: "0.01",
                  name: "meterLength",
                  placeholder: "0.00"
                },
                domProps: { value: _vm.room.meterLength1 },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.room, "meterLength1", $event.target.value)
                    },
                    this.getTotalMeterLength
                  ]
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "calculator__group" }, [
              _c(
                "label",
                {
                  staticClass: "calculator__label",
                  attrs: { for: "meterLength" }
                },
                [_vm._v("Enter Meter Length")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.room.meterLength2,
                    expression: "room.meterLength2"
                  }
                ],
                staticClass: "calculator__input",
                attrs: {
                  type: "number",
                  step: "0.01",
                  name: "meterLength",
                  placeholder: "0.00"
                },
                domProps: { value: _vm.room.meterLength2 },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.room, "meterLength2", $event.target.value)
                    },
                    this.getTotalMeterLength
                  ]
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "calculator__group" }, [
              _c(
                "label",
                {
                  staticClass: "calculator__label",
                  attrs: { for: "meterLength" }
                },
                [_vm._v("Enter Meter Length")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.room.meterLength3,
                    expression: "room.meterLength3"
                  }
                ],
                staticClass: "calculator__input",
                attrs: {
                  type: "number",
                  step: "0.01",
                  name: "meterLength",
                  placeholder: "0.00"
                },
                domProps: { value: _vm.room.meterLength3 },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.room, "meterLength3", $event.target.value)
                    },
                    this.getTotalMeterLength
                  ]
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "calculator__group" }, [
              _c(
                "label",
                {
                  staticClass: "calculator__label",
                  attrs: { for: "meterLength" }
                },
                [_vm._v("Enter Meter Length")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.room.meterLength4,
                    expression: "room.meterLength4"
                  }
                ],
                staticClass: "calculator__input",
                attrs: {
                  type: "number",
                  step: "0.01",
                  name: "meterLength",
                  placeholder: "0.00"
                },
                domProps: { value: _vm.room.meterLength4 },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.room, "meterLength4", $event.target.value)
                    },
                    this.getTotalMeterLength
                  ]
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "calculator__group calculator__group--flex" },
              [
                _c(
                  "label",
                  {
                    staticClass: "calculator__label",
                    attrs: { for: "ipRating" }
                  },
                  [_vm._v("Select IP Rating")]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "calculator__radio" }, [
                  _c("input", {
                    attrs: {
                      name: "ipRating",
                      id: "20",
                      type: "radio",
                      value: "0"
                    },
                    on: {
                      change: function($event) {
                        return _vm.onChange($event, "iprating")
                      }
                    }
                  }),
                  _c("label", { attrs: { for: "20" } }, [_vm._v(" 20")])
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "calculator__radio" }, [
                  _c("input", {
                    attrs: {
                      name: "ipRating",
                      id: "65",
                      type: "radio",
                      value: "2.00"
                    },
                    on: {
                      change: function($event) {
                        return _vm.onChange($event, "iprating")
                      }
                    }
                  }),
                  _c("label", { attrs: { for: "65" } }, [_vm._v(" 65")])
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "calculator__radio" }, [
                  _c("input", {
                    attrs: {
                      name: "ipRating",
                      id: "68",
                      type: "radio",
                      value: "3.33"
                    },
                    on: {
                      change: function($event) {
                        return _vm.onChange($event, "iprating")
                      }
                    }
                  }),
                  _c("label", { attrs: { for: "68" } }, [_vm._v(" 68")])
                ]),
                _vm._v(" "),
                _c("hr")
              ]
            )
          ]),
          _vm._v(" "),
          _c("accordion", { attrs: { name: "CSL" } }, [
            _vm.room.ipRating == 0
              ? _c("div", { staticClass: "calculator__group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "calculator__label",
                      attrs: { for: "quantity" }
                    },
                    [_vm._v("IP20")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "calculator__input calculator__input--number",
                    attrs: {
                      type: "number",
                      name: "quantity",
                      placeholder: "0"
                    },
                    on: {
                      input: function($event) {
                        return _vm.handleInput(
                          $event.target.value,
                          "IP20",
                          6.67,
                          "csl"
                        )
                      }
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.room.ipRating == 2.0
              ? _c("div", { staticClass: "calculator__group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "calculator__label",
                      attrs: { for: "quantity" }
                    },
                    [_vm._v("IP65")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "calculator__input calculator__input--number",
                    attrs: {
                      type: "number",
                      name: "quantity",
                      placeholder: "0"
                    },
                    on: {
                      input: function($event) {
                        return _vm.handleInput(
                          $event.target.value,
                          "IP65",
                          10.67,
                          "csl"
                        )
                      }
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.room.ipRating == 3.33
              ? _c("div", { staticClass: "calculator__group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "calculator__label",
                      attrs: { for: "quantity" }
                    },
                    [_vm._v("IP68")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "calculator__input calculator__input--number",
                    attrs: {
                      type: "number",
                      name: "quantity",
                      placeholder: "0"
                    },
                    on: {
                      input: function($event) {
                        return _vm.handleInput(
                          $event.target.value,
                          "IP68",
                          13.33,
                          "csl"
                        )
                      }
                    }
                  })
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "accordion",
            { attrs: { name: "Drivers" } },
            [
              _c(
                "accordion",
                { attrs: { name: "Non Dimmable" } },
                _vm._l(_vm.nonDimmable, function(nd) {
                  return _c("div", { staticClass: "calculator__group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "calculator__label",
                        attrs: { for: "quantity" }
                      },
                      [_vm._v(_vm._s(nd.name))]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      staticClass:
                        "calculator__input calculator__input--number",
                      attrs: {
                        type: "number",
                        name: "quantity",
                        placeholder: "0"
                      },
                      on: {
                        input: function($event) {
                          return _vm.handleInput(
                            $event.target.value,
                            nd.name,
                            nd.value,
                            "nonDimmableDrivers"
                          )
                        }
                      }
                    })
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "accordion",
                { attrs: { name: "Triac Dimmable" } },
                _vm._l(_vm.triacDimmable, function(td) {
                  return _c("div", { staticClass: "calculator__group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "calculator__label",
                        attrs: { for: "quantity" }
                      },
                      [_vm._v(_vm._s(td.name))]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      staticClass:
                        "calculator__input calculator__input--number",
                      attrs: {
                        type: "number",
                        name: "quantity",
                        placeholder: "0"
                      },
                      on: {
                        input: function($event) {
                          return _vm.handleInput(
                            $event.target.value,
                            td.name,
                            td.value,
                            "triacDimmableDrivers"
                          )
                        }
                      }
                    })
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "accordion",
                { attrs: { name: "1-10v Dimmable" } },
                _vm._l(_vm.voltDimmable, function(vd) {
                  return _c("div", { staticClass: "calculator__group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "calculator__label",
                        attrs: { for: "quantity" }
                      },
                      [_vm._v(_vm._s(vd.name))]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      staticClass:
                        "calculator__input calculator__input--number",
                      attrs: {
                        type: "number",
                        name: "quantity",
                        placeholder: "0"
                      },
                      on: {
                        input: function($event) {
                          return _vm.handleInput(
                            $event.target.value,
                            vd.name,
                            vd.value,
                            "voltDimmableDrivers"
                          )
                        }
                      }
                    })
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "accordion",
                { attrs: { name: "Dali Dimmable" } },
                _vm._l(_vm.daliDimmable, function(dd) {
                  return _c("div", { staticClass: "calculator__group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "calculator__label",
                        attrs: { for: "quantity" }
                      },
                      [_vm._v(_vm._s(dd.name))]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      staticClass:
                        "calculator__input calculator__input--number",
                      attrs: {
                        type: "number",
                        name: "quantity",
                        placeholder: "0"
                      },
                      on: {
                        input: function($event) {
                          return _vm.handleInput(
                            $event.target.value,
                            dd.name,
                            dd.value,
                            "daliDimmableDrivers"
                          )
                        }
                      }
                    })
                  ])
                }),
                0
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "accordion",
            { attrs: { name: "Controllers" } },
            _vm._l(_vm.controllers, function(ct) {
              return _c("div", { staticClass: "calculator__group" }, [
                _c(
                  "label",
                  {
                    staticClass: "calculator__label",
                    attrs: { for: "quantity" }
                  },
                  [_vm._v(_vm._s(ct.name))]
                ),
                _vm._v(" "),
                _c("input", {
                  staticClass: "calculator__input calculator__input--number",
                  attrs: { type: "number", name: "quantity", placeholder: "0" },
                  on: {
                    input: function($event) {
                      return _vm.handleInput(
                        $event.target.value,
                        ct.name,
                        ct.value,
                        "controllers"
                      )
                    }
                  }
                })
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "accordion",
            { attrs: { name: "Profile (Length)" } },
            [
              _vm._l(_vm.ourprofiles, function(pr) {
                return _c("div", { staticClass: "calculator__group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "calculator__label",
                      attrs: { for: "quantity" }
                    },
                    [_vm._v(_vm._s(pr.name))]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "calculator__input calculator__input--number",
                    attrs: {
                      type: "number",
                      name: "quantity",
                      placeholder: "0"
                    },
                    on: {
                      input: function($event) {
                        return _vm.handleInput(
                          $event.target.value,
                          pr.name,
                          pr.value,
                          "profiles"
                        )
                      }
                    }
                  })
                ])
              }),
              _vm._v(" "),
              !_vm.room.meterLength
                ? _c("p", [
                    _vm._v(
                      "Meter length must be inputted before chosing a profile."
                    )
                  ])
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c("accordion", { attrs: { name: "Room Notes" } }, [
            _c("div", [
              _c(
                "label",
                { staticClass: "calculator__label", attrs: { for: "notes" } },
                [_vm._v("Enter any room notes")]
              ),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.room.notes,
                    expression: "room.notes"
                  }
                ],
                staticClass: "calculator__textarea",
                attrs: {
                  name: "notes",
                  id: "notes",
                  cols: "30",
                  rows: "10",
                  placeholder: "Room Notes"
                },
                domProps: { value: _vm.room.notes },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.room, "notes", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _vm.errors.length
            ? _c("div", { staticClass: "calculator__errors" }, [
                _c("p", [_vm._v("Please correct the following error(s):")]),
                _vm._v(" "),
                _c(
                  "ul",
                  _vm._l(_vm.errors, function(error) {
                    return _c("li", [_vm._v(_vm._s(error))])
                  }),
                  0
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "navigation" }, [
            _c(
              "button",
              {
                staticClass: "ui-btn ui-btn--primary",
                on: { click: _vm.cancelRoom }
              },
              [_vm._v("Cancel")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "ui-btn ui-btn--secondary",
                on: { click: _vm.saveRoom }
              },
              [_vm._v("Save Room")]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "calculator__col" }, [
        _c("div", { staticClass: "info-box" }, [
          _c("div", { staticClass: "info-box__title" }, [
            _vm.room.name
              ? _c("h3", { staticClass: "heading heading--sm" }, [
                  _vm._v(_vm._s(_vm.room.name))
                ])
              : _c("h3", { staticClass: "heading heading--sm" }, [
                  _vm._v("Room Name")
                ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "info-box__content" }, [
            _c("p", [
              _c("strong", [_vm._v("Price Per Meter :")]),
              _vm._v("\n\t\t\t\t£" + _vm._s(_vm.room.pricePerMeter))
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("CSL Cost :")]),
              _vm._v("\n\t\t\t\t£" + _vm._s(_vm.totalCSLCost))
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Drivers Cost :")]),
              _vm._v("\n\t\t\t\t£" + _vm._s(_vm.totalDriverCost))
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Controllers Cost :")]),
              _vm._v("\n\t\t\t\t£" + _vm._s(_vm.totalControllersCost))
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Profile Cost :")]),
              _vm._v("\n\t\t\t\t£" + _vm._s(_vm.totalProfileCost))
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Total Room Cost :")]),
              _vm._v("\n\t\t\t\t£" + _vm._s(_vm.room.roomPrice))
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }