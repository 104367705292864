var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "calculator__inner" }, [
    _c(
      "div",
      { staticClass: "calculator__grid" },
      _vm._l(_vm.rooms, function(room) {
        return _c("div", { staticClass: "info-box" }, [
          _c("div", { staticClass: "info-box__title" }, [
            room.name
              ? _c("h3", { staticClass: "heading heading--sm" }, [
                  _vm._v(_vm._s(room.name))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "info-box__content" }, [
            _c("p", [
              _c("strong", [_vm._v("Wattage:")]),
              _vm._v("\n\t\t\t\t" + _vm._s(room.wattageName))
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Kelvin:")]),
              _vm._v("\n\t\t\t\t" + _vm._s(room.kelvin))
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Cost Per Meter:")]),
              _vm._v("\n\t\t\t\t£" + _vm._s(room.pricePerMeter))
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Total Room Cost :")]),
              _vm._v("\n\t\t\t\t£" + _vm._s(room.roomPrice))
            ])
          ])
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "calculator__bottom" }, [
      _c("div", { staticClass: "calculator__total" }, [
        _c("p", [
          _c("strong", [_vm._v("Total Project Cost:")]),
          _vm._v(" £" + _vm._s(_vm.projectCost))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "calculator__nav" }, [
        _c("p", [_vm._v("Add another room to your project")]),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "ui-btn ui-btn--secondary", on: { click: _vm.prev } },
          [_vm._v("Add Room")]
        )
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "calculator__form",
        attrs: { id: "contact" },
        domProps: { innerHTML: _vm._s(_vm.contact) }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }