var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "calculator" },
    [
      !_vm.projectName
        ? _c("h1", { staticClass: "heading heading--md heading--mblg" }, [
            _vm._v("Add a Project")
          ])
        : _c("h1", { staticClass: "heading heading--md heading--mblg" }, [
            _vm._v(" " + _vm._s(_vm.projectName) + " ")
          ]),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            appear: "",
            "appear-active-class": "animated fadeInRight",
            name: "custom-classes-transition",
            "enter-active-class": "animated fadeInRight",
            "leave-active-class": "animated fadeOutLeft",
            mode: "out-in"
          }
        },
        [
          1 === _vm.activeField
            ? _c("projectDetails", {
                on: {
                  "project-name": function($event) {
                    return _vm.setProjectName($event)
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          2 === _vm.activeField
            ? _c("room", {
                on: {
                  "add-room-data": function($event) {
                    return _vm.addRoomDetails($event)
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          3 === _vm.activeField
            ? _c("projectOverview", { attrs: { projectName: _vm.projectName } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }