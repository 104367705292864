export const calculator = {
	state: {
		projectName: '',
		rooms: [],
		wattages: [
			{ 'name': "4.8", 'value': 5.32 },
			{ 'name': "9.6", 'value': 9.32 },
			{ 'name': "14.4 rgb", 'value': 13.31 },
			{ 'name': "14.4 rgbw",  'value': 18.65 },
		],
		csl: [
			{ 'name': 'IP20', 'value': 6.67 },
			{ 'name': 'IP65', 'value': 10.67 },
			{ 'name': 'IP68', 'value': 13.33 },
		],
		nonDimmable: [
			{ 'name': '12w (AL-APV-12-24)', 'value': 8.67 },
			{ 'name': '24w (AL-EPV-24-24)', 'value': 9.27 },
			{ 'name': '40w (AL-EPV-40-24)', 'value': 14.60 },
			{ 'name': '60w (AL-EPV-60-24)', 'value': 19.76 },
			{ 'name': '100w (AL-EPV-100-24)', 'value': 33.00 },
			{ 'name': '150w (AL-LPV-150-24)', 'value': 43.80 },
			{ 'name': '200w	(AL-ELG-200-24)', 'value': 57.79 },
			{ 'name': '320w	(AL-HLG-320H-24A)', 'value': 106.68 },
			{ 'name': '480w	(AL-HLG-480H-24A)', 'value': 148.60 },
		],
		triacDimmable: [
			{ 'name': '15w (AL-ELED-15-24T)', 'value': 19.05},
			{ 'name': '25w (AL-ELED-25-24T)', 'value': 23.81},
			{ 'name': '50w (AL-ELED-50-24T)', 'value': 32.24},
			{ 'name': '100w (AL-ELED-100-24T)', 'value': 57.05},
			{ 'name': '150w (AL-ELED-150-24T)', 'value': 80.00},
			{ 'name': '200w (AL-ELED-200-24T)', 'value': 91.45},
		],
		voltDimmable: [
			{ 'name': '20w', 'value': 14.28},
			{ 'name': '45w', 'value': 23.81},
			{ 'name': '60w', 'value': 33.14},
			{ 'name': '100w', 'value': 55.05},
			{ 'name': '200w', 'value': 81.81},
		],
		daliDimmable: [
			{ 'name': '25w (AL-ELED-25-24D)', 'value': 32.19},
			{ 'name': '50w (AL-ELED-50-24D)', 'value': 53.72},
			{ 'name': '100w (AL-ELED-100-24D)', 'value': 77.57},
			{ 'name': '150w (AL-ELED-150-24D)', 'value': 70.48},
			{ 'name': '200w (AL-ELED-200-24D)', 'value': 97.14},
		],
		controllers: [
			{ 'name': 'RGB/W Receiver (AL-WAPP5)', 'value': 31.99},
			{ 'name': 'RGB/W Receiver (AL-WAPP5-IP)', 'value': 53.00},
			{ 'name': 'RGB/W Amplifier (AL-WAPP-AMPLIFIER)', 'value': 19.99},
			{ 'name': 'RGB/W Wall controller 4 ZONE/SMART White (AL-WALLRGB-CCT)', 'value': 33.34},
			{ 'name': 'RGB/W Wall controller 4 ZONE/SMART Black (AL-WALLRGB-CCT)', 'value': 33.34},
			{ 'name': 'RGB/W Wall controller 8 ZONE ', 'value': 46.60},
			{ 'name': 'Single Colour Remote White (AL-WALLSC4Z)', 'value': 21.27},
			{ 'name': 'Single Colour Remote Black (AL-WALLSC4Z)', 'value': 21.27},
			{ 'name': 'RGB/W Remote White (AL-RM8Z)', 'value': 30.60},
			{ 'name': 'RGB/W Remote Black (AL-RM8Z)', 'value': 30.60},
			{ 'name': 'DMX 5 IN 1 CHANNEL DECODER (AL-DMX-DECODER)', 'value': 53.34},
			{ 'name': 'RGB/W WALL Controller  RF/DMX White (AL-RGB-WALLCONTROL)', 'value': 60.67},


			// { 'name': 'Wall controller  4 ZONE single colour ', 'value': 21.27},
			// { 'name': 'RGB/W WALL Controller  RF/DMX BLACK ', 'value': 60.67},
			// { 'name': 'RGB/W WALL Controller  RF/DMX White ', 'value': 60.67},
			// { 'name': 'RGB/W Wifi GATEWAY', 'value': 46.67},
		],
		profiles: [
			{ 'name': 'Recessed Shallow (AL-LIP-02-AL)', 'value': 6.67},
			{ 'name': 'Recessed Deep (AL-LIP-03-AL)', 'value': 6.67},
			{ 'name': 'Recessed Wide (AL-LIP-07-AL)', 'value': 6.67},
			{ 'name': 'Surface Mini (AL-SMAP-01-AL)', 'value': 6.67},
			{ 'name': 'Surface Shallow (AL-SMAP-02-AL)', 'value': 6.67},
			{ 'name': 'Surface Deep (AL-SMAP-03-AL)', 'value': 6.67},
			{ 'name': 'Surface Wide (AL-SMAP-07-AL)', 'value': 6.67},
			{ 'name': 'Surface Wide & Deep (AL-SMAP-09-AL)', 'value': 13.82},
			{ 'name': 'Plaster in Profile Small (AL-PLIP-SMALL)', 'value': 13.32},
			{ 'name': 'Plaster in Profile Medium (AL-PLIP)', 'value': 14},
			{ 'name': 'Plaster in Profile Deep AL-PLIP-DEEP', 'value': 14},
			{ 'name': 'Plaster in Profile Wide (AL-PLIP-WIDE)', 'value': 19.34},
			{ 'name': 'Corner Profile (AL-CAP-01-AL)', 'value': 9.34},
			{ 'name': 'Corner Profile Aluminium (AL-CAPPRO8-ALUM)', 'value': 13.27},
			// {
			// 	'name': "Recessed",
			// 	'items': [
			// 		{
			// 			'name': 'Shallow',
			// 			'value': 7.28
			// 		},
			// 		{
			// 			'name': 'Deep',
			// 			'value': 7.67
			// 		},
			// 		{
			// 			'name': 'Wide',
			// 			'value': 7.67
			// 		},
			// 	]
			// },
			// {
			// 	'name': "Surface",
			// 	'items': [
			// 		{
			// 			'name': 'Shallow',
			// 			'value': 7.28
			// 		},
			// 		{
			// 			'name': 'Deep',
			// 			'value': 7.67
			// 		},
			// 		{
			// 			'name': 'Wide',
			// 			'value': 7.67
			// 		},
			// 	]
			// },
			// {
			// 	'name': "Plaster in Profile",
			// 	'items': [
			// 		{
			// 			'name': 'Small',
			// 			'value': 13.32
			// 		},
			// 		{
			// 			'name': 'Medium',
			// 			'value': 14
			// 		},
			// 		{
			// 			'name': 'Wide',
			// 			'value': 19.33
			// 		},
			// 	]
			// },
		]
	},

	actions: {
		calcRoomTotal( context, room ) {
			console.log('made it here..');
		}
	},

	mutations: {
		resetAllData( state ) {
		},
		addRoom( state, value ) {
			state.rooms.push(value);
		},
	},

	getters: {
		getProjectRoom: state => {
			return state.projectName;
		},

		getWattages: state => {
			return state.wattages;
		},

		getCSL: state => {
			return state.csl;
		},

		getNonDimmable: state => {
			return state.nonDimmable;
		},

		getTriacDimmable: state => {
			return state.triacDimmable;
		},

		getVoltDimmable: state => {
			return state.voltDimmable;
		},

		getDaliDimmable: state => {
			return state.daliDimmable;
		},

		getControllers: state => {
			return state.controllers;
		},

		getProfiles: state => {
			return state.profiles;
		},

		getAllRooms( state ) {
			return state.rooms;
		},

		getTotalCost(state) {
			let projectCost = 0;
			state.rooms.forEach( room => {
				projectCost += parseFloat(room.roomPrice);
			});

			return projectCost.toFixed(2);
		}
	}
}
