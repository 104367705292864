var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "calculator__inner" }, [
    _c("div", { staticClass: "calculator__col" }, [
      _c("div", { staticClass: "calculator__group" }, [
        _c(
          "label",
          { staticClass: "calculator__label", attrs: { for: "project-name" } },
          [_vm._v("Enter Project Name ")]
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.projectName,
              expression: "projectName"
            }
          ],
          staticClass: "calculator__input",
          attrs: {
            type: "text",
            name: "project-name",
            placeholder: "Enter Project Name"
          },
          domProps: { value: _vm.projectName },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.projectName = $event.target.value
              },
              function($event) {
                return _vm.emitProjectName(_vm.projectName)
              }
            ]
          }
        })
      ])
    ]),
    _vm._v(" "),
    _vm.errors.length
      ? _c("div", { staticClass: "calculator__errors" }, [
          _c("p", [_vm._v("Please correct the following error(s):")]),
          _vm._v(" "),
          _c(
            "ul",
            _vm._l(_vm.errors, function(error) {
              return _c("li", [_vm._v(_vm._s(error))])
            }),
            0
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "calculator__bottom" }, [
      _c("div", { staticClass: "calculator__nav" }, [
        _c("p", [_vm._v("Add a room to your project")]),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "ui-btn ui-btn--secondary", on: { click: _vm.next } },
          [_vm._v("Add Room")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }