<template>
	<div class="calculator__inner calculator__inner--flex">
		<div class="calculator__col">
			<accordion name="Room Details">
				<div class="calculator__group">
					<label for="project-name" class="calculator__label">Enter Room Name</label>
					<input
							class="calculator__input"
							type="text"
							name="room-name"
							v-model="room.name"
							placeholder="Room Name"
						>
				</div>
				<div class="calculator__group">
					<label for="wattage" class="calculator__label">Select Wattage</label>
					<select @change="onChange($event, 'wattage')" class="calculator__input calculator__input--select" name="wattage">
						<option disabled selected value="">Please select one</option>
						<option v-for="wattage in wattages" :value="wattage.value">{{wattage.name}}</option>
					</select>
				</div>

				<div class="calculator__group calculator__group--flex" v-if="room.wattageName === '4.8' || room.wattageName === '9.6'">
					<label for="kelvin" class="calculator__label">Select Kelvin</label>
					 <p class="calculator__radio">
					 	<input id="2700" type="radio" value="2700" v-model="room.kelvin">
					 	<label for="2700">2700</label>
					 </p>
					 <p class="calculator__radio">
					 	<input id="3000" type="radio" value="3000" v-model="room.kelvin">
					 	<label for="3000">3000</label>
					 </p>
					 <p class="calculator__radio">
					 	<input id="4000" type="radio" value="4000" v-model="room.kelvin">
					 	<label for="4000">4000</label>
					 </p>
					 <p class="calculator__radio">
					 	<input id="6000" type="radio" value="6000" v-model="room.kelvin">
					 	<label for="6000">6000</label>
					 </p>
				</div>

				<div class="calculator__group calculator__group--flex" v-if="room.wattageName === '14.4 rgb' || room.wattageName === '14.4 rgbw'">
					<label for="kelvin" class="calculator__label">Select Kelvin</label>
					 <p class="calculator__radio">
					 	<input id="RGB" type="radio" value="RGB" v-model="room.kelvin">
					 	<label for="RGB">RGB</label>
					 </p>
					 <p class="calculator__radio">
					 	<input id="RGBWW+2700K" type="radio" value="RGBWW+2700K" v-model="room.kelvin">
					 	<label for="RGBWW+2700K">RGBWW+2700K</label>
					 </p>
					 <p class="calculator__radio">
					 	<input id="RGBW+4000K" type="radio" value="RGBW+4000K" v-model="room.kelvin">
					 	<label for="RGBW+4000K">RGBW+4000K</label>
					 </p>
					 <p class="calculator__radio">
					 	<input id="RGBCW+6000K" type="radio" value="RGBCW+6000K" v-model="room.kelvin">
					 	<label for="RGBCW+6000K">RGBCW+6000K</label>
					 </p>
				</div>

				<div class="calculator__group">
					<label for="meterLength" class="calculator__label">Enter Meter Length</label>
					<input
						v-model="room.meterLength1"
						class="calculator__input"
						type="number"
						step='0.01'
						name="meterLength"
						placeholder="0.00"
						@input="this.getTotalMeterLength"
					>
				</div>
				<div class="calculator__group">
					<label for="meterLength" class="calculator__label">Enter Meter Length</label>
					<input
						v-model="room.meterLength2"
						class="calculator__input"
						type="number"
						step='0.01'
						name="meterLength"
						placeholder="0.00"
						@input="this.getTotalMeterLength"
					>
				</div>
				<div class="calculator__group">
					<label for="meterLength" class="calculator__label">Enter Meter Length</label>
					<input
						v-model="room.meterLength3"
						class="calculator__input"
						type="number"
						step='0.01'
						name="meterLength"
						placeholder="0.00"
						@input="this.getTotalMeterLength"
					>
				</div>
				<div class="calculator__group">
					<label for="meterLength" class="calculator__label">Enter Meter Length</label>
					<input
						v-model="room.meterLength4"
						class="calculator__input"
						type="number"
						step='0.01'
						name="meterLength"
						placeholder="0.00"
						@input="this.getTotalMeterLength"
					>
				</div>

				<div class="calculator__group calculator__group--flex">
					<label for="ipRating" class="calculator__label">Select IP Rating</label>
					 <p class="calculator__radio"><input name="ipRating" id="20" type="radio" value="0" @change="onChange($event, 'iprating')"><label for="20"> 20</label></p>
					 <p class="calculator__radio"><input name="ipRating" id="65" type="radio" value="2.00" @change="onChange($event, 'iprating')" ><label for="65" > 65</label></p>
					 <p class="calculator__radio"><input name="ipRating" id="68" type="radio" value="3.33" @change="onChange($event, 'iprating')" ><label for="68" > 68</label></p>
					 <hr>
				</div>
			</accordion>

			<accordion name="CSL">
				<div class="calculator__group" v-if="room.ipRating == 0">
					<label for="quantity" class="calculator__label">IP20</label>
					<input
						class="calculator__input calculator__input--number"
						type="number"
						name="quantity"
						placeholder="0"
						@input="handleInput($event.target.value, 'IP20', 6.67, 'csl')"
					>
				</div>
				<div class="calculator__group" v-if="room.ipRating == 2.00">
					<label for="quantity" class="calculator__label">IP65</label>
					<input
						class="calculator__input calculator__input--number"
						type="number"
						name="quantity"
						placeholder="0"
						@input="handleInput($event.target.value, 'IP65', 10.67, 'csl')"
					>
				</div>
				<div class="calculator__group" v-if="room.ipRating == 3.33">
					<label for="quantity" class="calculator__label">IP68</label>
					<input
						class="calculator__input calculator__input--number"
						type="number"
						name="quantity"
						placeholder="0"
						@input="handleInput($event.target.value, 'IP68', 13.33, 'csl')"
					>
				</div>
			</accordion>

			<accordion name="Drivers">
				<accordion name="Non Dimmable">
					<div v-for="nd in nonDimmable" class="calculator__group" >
						<label for="quantity" class="calculator__label">{{ nd.name }}</label>
					  <input
					  	class="calculator__input calculator__input--number"
					  	type="number"
					  	name="quantity"
					  	placeholder="0"
					  	@input="handleInput($event.target.value, nd.name, nd.value, 'nonDimmableDrivers')"
					  	>
					</div>
				</accordion>

				<accordion name="Triac Dimmable">
					<div v-for="td in triacDimmable" class="calculator__group" >
						<label for="quantity" class="calculator__label">{{ td.name }}</label>
						<input
							class="calculator__input calculator__input--number"
							type="number"
							name="quantity"
							placeholder="0"
							@input="handleInput($event.target.value, td.name, td.value, 'triacDimmableDrivers')"
						>
					</div>
				</accordion>

				<accordion name="1-10v Dimmable">
					<div v-for="vd in voltDimmable" class="calculator__group" >
						<label for="quantity" class="calculator__label">{{ vd.name }}</label>
						<input
							class="calculator__input calculator__input--number"
							type="number"
							name="quantity"
							placeholder="0"
							@input="handleInput($event.target.value, vd.name, vd.value, 'voltDimmableDrivers')"
						>
					</div>
				</accordion>

				<accordion name="Dali Dimmable">
					<div v-for="dd in daliDimmable" class="calculator__group" >
						<label for="quantity" class="calculator__label">{{ dd.name }}</label>
						<input
							class="calculator__input calculator__input--number"
							type="number"
							name="quantity"
							placeholder="0"
							@input="handleInput($event.target.value, dd.name, dd.value, 'daliDimmableDrivers')"
						>
					</div>
				</accordion>
			</accordion>

			<accordion name="Controllers">
				<div v-for="ct in controllers" class="calculator__group" >
					<label for="quantity" class="calculator__label">{{ ct.name }}</label>
					<input
						class="calculator__input calculator__input--number"
						type="number"
						name="quantity"
						placeholder="0"
						@input="handleInput($event.target.value, ct.name, ct.value, 'controllers')"
					>
				</div>
			</accordion>

			<accordion name="Profile (Length)">
				<!-- <div class="calculator__group calculator__group--flex" v-if="room.meterLength">
					<p v-for="pr in profiles" class="calculator__radio">
						<input :id="pr.name" name="profile" type="radio" :value="pr.value" @change="onChange($event, 'profile')">
						<label :for="pr.name" > {{ pr.name }}</label>
					</p>
				</div> -->
				<div v-for="pr in ourprofiles" class="calculator__group" >
					<label for="quantity" class="calculator__label">{{ pr.name }}</label>
					<input
						class="calculator__input calculator__input--number"
						type="number"
						name="quantity"
						placeholder="0"
						@input="handleInput($event.target.value, pr.name, pr.value, 'profiles')"
					>
				</div>
				<p v-if="!room.meterLength">Meter length must be inputted before chosing a profile.</p>
			</accordion>

			<accordion name="Room Notes">
				<div>
					<label for="notes" class="calculator__label">Enter any room notes</label>
					<textarea name="notes" id="notes" cols="30" rows="10" v-model="room.notes" class="calculator__textarea" placeholder="Room Notes"></textarea>
				</div>
			</accordion>


		  <div v-if="errors.length" class="calculator__errors">
		    <p>Please correct the following error(s):</p>
		    <ul>
		      <li v-for="error in errors">{{ error }}</li>
		    </ul>
		  </div>

			<div class="navigation">
				<button class="ui-btn ui-btn--primary" @click="cancelRoom">Cancel</button>
				<button class="ui-btn ui-btn--secondary" @click="saveRoom">Save Room</button>
			</div>
		</div>
		<div class="calculator__col">
			<div class="info-box">
				<div class="info-box__title">
					<h3 class="heading heading--sm" v-if="room.name">{{ room.name }}</h3>
					<h3 class="heading heading--sm" v-else>Room Name</h3>
				</div>
				<div class="info-box__content">
					<p><strong>Price Per Meter :</strong>
					£{{ room.pricePerMeter }}</p>

					<p><strong>CSL Cost :</strong>
					£{{ totalCSLCost }}</p>

					<p><strong>Drivers Cost :</strong>
					£{{ totalDriverCost }}</p>

					<p><strong>Controllers Cost :</strong>
					£{{ totalControllersCost }}</p>

					<p><strong>Profile Cost :</strong>
					£{{ totalProfileCost }}</p>

					<p><strong>Total Room Cost :</strong>
					£{{ room.roomPrice }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import accordion from '../accordion';

	export default {
		components: {
			accordion
		},

		data() {
			return {
				errors: [],
				room: {
					name: '',
					meterLength: '',
					meterLength1: '',
					meterLength2: '',
					meterLength3: '',
					meterLength4: '',
					pricePerMeter: '0.00',
					wattageValue: '0.00',
					wattageName: '',
					kelvin: '',
					ipRating: 0,
					ipRatingName: '',
					notes: '',
					csl: [],
					nonDimmableDrivers: [],
					triacDimmableDrivers: [],
					voltDimmableDrivers: [],
					daliDimmableDrivers: [],
					controllers: [],
					roomPrice: '0.00',
					profiles: [],
					// profileName: '',
					// profileCost: '0.00',
				},
			}
		},

		computed: {
			...mapGetters({
				wattages: 'getWattages',
				// csl: 'getCSL',
				nonDimmable: 'getNonDimmable',
				triacDimmable: 'getTriacDimmable',
				voltDimmable: 'getVoltDimmable',
				daliDimmable: 'getDaliDimmable',
				controllers: 'getControllers',
				ourprofiles: 'getProfiles',
			}),

			totalDriverCost: function() {
				var nonDimmableCost = this.room.nonDimmableDrivers.reduce((acc, item) => parseFloat(acc) + parseFloat(item.cost), 0);
				var triacDimmableCost = this.room.triacDimmableDrivers.reduce((acc, item) => parseFloat(acc) + parseFloat(item.cost), 0);
				var voltDimmableCost = this.room.voltDimmableDrivers.reduce((acc, item) => parseFloat(acc) + parseFloat(item.cost), 0);
				var daliDimmableCost = this.room.daliDimmableDrivers.reduce((acc, item) => parseFloat(acc) + parseFloat(item.cost), 0);

				return parseFloat(nonDimmableCost + triacDimmableCost + voltDimmableCost + daliDimmableCost).toFixed(2);
			},

			totalProfileCost: function() {
				return parseFloat(this.room.profiles.reduce((acc, item) => parseFloat(acc) + parseFloat(item.cost), 0)).toFixed(2);
			},

			totalCSLCost: function() {
				return parseFloat(this.room.csl.reduce((acc, item) => parseFloat(acc) + parseFloat(item.cost), 0)).toFixed(2);
			},

			totalControllersCost: function() {
				return parseFloat(this.room.controllers.reduce((acc, item) => parseFloat(acc) + parseFloat(item.cost), 0)).toFixed(2);
			},
		},

	    methods: {
	        onChange(event, type) {
	        	if(type == 'wattage') {
	        		this.room.wattageName = event.target.options[event.target.options.selectedIndex].text;
	        		this.room.wattageValue = parseFloat(event.target.value).toFixed(2);
	        	// } else if(type == 'profile') {
	        	// 	this.room.profileName = event.target.id;
	        	// 	this.room.profileCost = parseFloat(event.target.value * this.room.meterLength).toFixed(2);
	        	} else if(type == 'iprating') {
	        		this.room.ipRatingName = event.target.id;
	        		this.room.ipRating = parseFloat(event.target.value).toFixed(2);
	        	}
	        	this.calcRoomPrice();
	        },


			handleInput(value, inputName, price, objectName) {
				var room = eval("this.room."+objectName);

				var index = room.findIndex(x => x.name== inputName);
				var newObj = {
					name: inputName,
					quantity:  value,
					cost: parseFloat(value * price).toFixed(2),
				};

				if(index === -1) {
					 room.push(newObj);
				} else if(value == 0 || value == 'NULL') {
					room.splice(index, 1);
				} else {
					this.$set(room[index], 'name', inputName);
					this.$set(room[index], 'quantity', value);
					this.$set(room[index], 'cost', parseFloat(value * price).toFixed(2));
					// room[index]
					// room[index] = newObj;
				}

				this.calcRoomPrice();
			},

			getTotalMeterLength() {
	            var input = document.getElementsByName('meterLength');
	            var sum = 0;
	            for (var i = 0; i < input.length; i++) {
	                var a = input[i];
	                var value = parseFloat(a.value)

	                if (!isNaN(value) && value.length != 0) {
	                	sum += parseFloat(value);
	                }
	            }


	            this.room.meterLength = sum;
	            this.calcRoomPrice();
			},


	        saveRoom() {
			      this.errors = [];

			      if (!this.room.name) {
			        this.errors.push('Room Name required.');
			      }

			      if (!this.room.wattageName) {
			        this.errors.push('Please select a wattage.');
			      }

			      if (!this.room.kelvin) {
			        this.errors.push('Please select a Kelvin.');
			      }

			      if (!this.room.meterLength || this.room.meterLength < 1) {
			        this.errors.push('Please enter a meter length.');
			      }

			      if (this.room.pricePerMeter == "0.00") {
			        this.errors.push('Price per meter is required.');
			      }

			      if (!this.room.profiles) {
			        this.errors.push('Please select a profile.');
			      }

			      if(!this.errors.length) {
			 		this.$store.commit("addRoom", this.room);
			 		this.$parent.next();
			      }
	        },

	        cancelRoom() {
	        	this.$parent.next();
	        },

			calcRoomPrice() {
				this.$forceUpdate();
				var meterCost  = 0;
				var extrasCost = 0;
				var profileCost = 0;
				var roomCost = 0;


				if(this.room.meterLength) {
			 		meterCost = parseFloat(this.room.pricePerMeter) * this.room.meterLength;
			 		extrasCost = parseFloat(this.totalDriverCost) + parseFloat(this.totalCSLCost) + parseFloat(this.totalControllersCost) + parseFloat(this.totalProfileCost);
			 		// profileCost = parseFloat(this.room.profileCost);
			 		this.room.roomPrice = parseFloat(meterCost + extrasCost + profileCost).toFixed(2);
				}
			}
	    },

		watch: {
			'room.ipRating': function (newVal, oldVal){
				this.room.pricePerMeter = parseFloat(newVal) + parseFloat(this.room.wattageValue);
				this.calcRoomPrice();
			 },

			 'room.wattageValue': function (newVal, oldVal){
				this.room.pricePerMeter = parseFloat(newVal) + parseFloat(this.room.ipRating);
				this.calcRoomPrice();
			 },
		},
	};
</script>
