<template>
	<div class="calculator__inner">
		<div class="calculator__grid">
			<div v-for="room in rooms" class="info-box">
				<div class="info-box__title">
					<h3 class="heading heading--sm" v-if="room.name">{{ room.name }}</h3>
				</div>
				<div class="info-box__content">
					<p><strong>Wattage:</strong>
					{{ room.wattageName }}</p>

					<p><strong>Kelvin:</strong>
					{{ room.kelvin }}</p>

					<p><strong>Cost Per Meter:</strong>
					£{{ room.pricePerMeter }}</p>

					<p><strong>Total Room Cost :</strong>
					£{{ room.roomPrice }}</p>
				</div>
			</div>
		</div>

		<div class="calculator__bottom">
			<div class="calculator__total" >
				<p><strong>Total Project Cost:</strong> £{{ projectCost }}</p>
			</div>

			<div class="calculator__nav">
				<p>Add another room to your project</p>
				<button class="ui-btn ui-btn--secondary" @click="prev">Add Room</button>
			</div>

			<div id="contact" class="calculator__form" v-html="contact"></div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		props: {
			projectName: {
				required: true
			}
		},

		data() {
			return {
				contact: ''
			}
		},

		mounted() {
			let self = this;
			this.contact = calculatorContacts['projectContactForm'];

	    	let roomHtml = '';
			this.rooms.forEach( room => {
				roomHtml += "Room Name: "+room.name+"\r\n\r\n";
				roomHtml += "Wattage: "+room.wattageName+"\r\n";
				roomHtml += "Kelvin: "+room.kelvin+"\r\n";
				roomHtml += "IP Rating: "+room.ipRatingName+"\r\n";
				if(room.meterLength1 != '') {
					roomHtml += "Meter Length: "+room.meterLength1+"\r\n";
				}
				if(room.meterLength2 != '') {
					roomHtml += "Meter Length: "+room.meterLength2+"\r\n";
				}
				if(room.meterLength3 != '') {
					roomHtml += "Meter Length: "+room.meterLength3+"\r\n";
				}
				if(room.meterLength4 != '') {
					roomHtml += "Meter Length: "+room.meterLength4+"\r\n";
				}
				roomHtml += "Total Meter Length: "+room.meterLength+"\r\n";
				roomHtml += "Price Per Meter : "+room.pricePerMeter+"\r\n";
				roomHtml += "\r\n\r\n";

				if(room.csl.length) {
					roomHtml += "Cutting/Soldering/Leading"+"\r\n";

					room.csl.forEach( csl => {
						roomHtml += csl.name+" x "+csl.quantity+"\r\n";
					});

					roomHtml += "\r\n\r\n";
				}

				if(room.nonDimmableDrivers.length) {
					roomHtml += "Non Dimmable Drivers"+"\r\n";

					room.nonDimmableDrivers.forEach( nonDimmableDrivers => {
						let ndSingleCost = parseFloat(nonDimmableDrivers.cost/nonDimmableDrivers.quantity).toFixed(2);
						roomHtml += nonDimmableDrivers.name+" x "+nonDimmableDrivers.quantity+" (£"+ndSingleCost+")\r\n";
					});

					roomHtml += "\r\n\r\n";
				}

				if(room.triacDimmableDrivers.length) {
					roomHtml += "Triac Dimmable"+"\r\n";

					room.triacDimmableDrivers.forEach( triacDimmableDrivers => {
						let tdSingleCost = parseFloat(triacDimmableDrivers.cost/triacDimmableDrivers.quantity).toFixed(2);
						roomHtml += triacDimmableDrivers.name+" x "+triacDimmableDrivers.quantity+" (£"+tdSingleCost+")\r\n";
					});

					roomHtml += "\r\n\r\n";
				}

				if(room.voltDimmableDrivers.length) {
					roomHtml += "1-10v Dimmable"+"\r\n";

					room.voltDimmableDrivers.forEach( voltDimmableDrivers => {
						let vdSingleCost = parseFloat(voltDimmableDrivers.cost/voltDimmableDrivers.quantity).toFixed(2);
						roomHtml += voltDimmableDrivers.name+" x "+voltDimmableDrivers.quantity+" (£"+vdSingleCost+")\r\n";
					});

					roomHtml += "\r\n\r\n";
				}

				if(room.daliDimmableDrivers.length) {
					roomHtml += "Dali Dimmable"+"\r\n";

					room.daliDimmableDrivers.forEach( daliDimmableDrivers => {
						let ddSingleCost = parseFloat(daliDimmableDrivers.cost/daliDimmableDrivers.quantity).toFixed(2);
						roomHtml += daliDimmableDrivers.name+" x "+daliDimmableDrivers.quantity+" (£"+ddSingleCost+")\r\n";
					});

					roomHtml += "\r\n\r\n";
				}


				if(room.controllers.length) {
					roomHtml += "Controllers"+"\r\n";

					room.controllers.forEach( controllers => {
						let cSingleCost = parseFloat(controllers.cost/controllers.quantity).toFixed(2);
						roomHtml += controllers.name+" x "+controllers.quantity+" (£"+cSingleCost+")\r\n";
					});

					roomHtml += "\r\n\r\n";
				}

				if(room.profiles.length) {
					roomHtml += "Profiles"+"\r\n";

					room.profiles.forEach( profiles => {
						roomHtml += profiles.name+" x "+profiles.quantity+"m (£"+profiles.cost+")\r\n";
					});

					roomHtml += "\r\n\r\n";
				}


				roomHtml += "Notes: "+room.notes+"\r\n";
				roomHtml += "\r\n\r\n";

				roomHtml += "---------------------------------------"+"\r\n";
				roomHtml += "Room Cost: £"+room.roomPrice+"\r\n";
				roomHtml += "---------------------------------------"+"\r\n";
				roomHtml += "\r\n\r\n";
			});

				roomHtml += "---------------------------------------"+"\r\n";
				roomHtml += "Total Cost: £"+this.projectCost+"\r\n";
				roomHtml  += "---------------------------------------"+"\r\n";


			setTimeout( function() {
    			let subject = document.querySelector('input[name="your-subject"]');
    			subject.value = self.projectName;

				if(self.rooms.length) {
	    			let roomDetails = document.querySelector('textarea[name="your-message"]');
	    			roomDetails.value = roomHtml;
	    			roomDetails.readOnly = true;
				}
			}, 200);
		},

		computed: {
			...mapGetters({
				rooms: 'getAllRooms',
				projectCost: 'getTotalCost',
			}),
		},

		methods: {
			prev() {
				this.$parent.prev();
			}
		},

		created() {
			//Have to re-add cf7 script as html didn't exist when it first loaded
			let cf7Script = document.createElement('script')
		    cf7Script.setAttribute('src', calculatorContacts.pluginsUrl + '/contact-form-7/includes/js/index.js')
		    document.head.appendChild(cf7Script);
		}
	};
</script>
