<template>
  <div class="accordion" :class="accordionClasses">
    <div class="accordion__header" @click="toggleAccordion">
      {{ name }}
    </div>
    <div class="accordion__body">
      <div class="accordion__content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
	name: 'Accordion',
	components: {},
	props: {
		name: String
	},
	data() {
		return {
			active: false
		}
	},
      computed: {
        accordionClasses: function() {
          return {
            'accordion--closed': !this.active,
            'accordion--open': this.active,
          };
        }
      },

	methods: {
		toggleAccordion() {
			this.active = !this.active
		}
	},
};
</script>
