<template>
	<div class="calculator__inner">
		<div class="calculator__col">
			<div class="calculator__group">
				<label for="project-name" class="calculator__label">Enter Project Name </label>
				<input
					class="calculator__input"
					type="text"
					name="project-name"
					v-model="projectName"
					@input="emitProjectName(projectName)"
					placeholder="Enter Project Name"
				>
			</div>
		</div>

		  <div v-if="errors.length" class="calculator__errors">
		    <p>Please correct the following error(s):</p>
		    <ul>
		      <li v-for="error in errors">{{ error }}</li>
		    </ul>
		  </div>


		<div class="calculator__bottom" >
			<div class="calculator__nav">
				<p>Add a room to your project</p>
				<button class="ui-btn ui-btn--secondary" @click="next">Add Room</button>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		data() {
			return {
				projectName: '',
				errors: [],
			}
		},

		methods: {
			emitProjectName( projectName ) {
				this.errors = [];

				if (!this.projectName) {
					this.errors.push('Project Name required.');
				} else {
					this.$emit( 'project-name', projectName );
				}
			},

			next() {
				this.errors = [];

				if (!this.projectName) {
				this.errors.push('Project Name required.');
				} else {
					this.$parent.next();
				}
			}
		},
	};
</script>
